import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
  import "./Header.scss";
  import LogoImgD from "../../images/logo-d.svg";
  import LogoImgT from "../../images/logo-t.svg";
  import LogoImgM from "../../images/logo-m.svg";
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby";
import GetURL from '@Components/common/site/get-url';
import {
  isServicePage,
  isAboutPage,
  isContactPage,
  isPropertyPage,
  isPropertiesResultsPage
} from "@Components/common/site/get-pages";
import {
  VALUATION_PAGE_URL, CONTACT_PAGE_URL, BAC_PAGE_URL
} from "@Components/common/site/constants";
import { isSafari, isIOS, isMacOs } from 'react-device-detect';
import $ from "jquery";
// import SearchOverlay from "@Components/searchOverlay/SearchOverlay";

const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || '';
export const uknumberformat = (x) => {
  if (!x) return x; 
  const currentValue = x.toString().replace(/[^\d]/g, '');
  return `${currentValue.slice(0, 5)} ${currentValue.slice(5, 11)}`; 
}
const Header = (props) => {
  const [scroll, setScroll] = useState(false);
  const [active, setActive] = useState (
    isPropertyPage() || isPropertiesResultsPage() ? 0 : isServicePage() ? 1 : isAboutPage() ? 2 : isContactPage() ? 3 : null
  )
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 60)
    })    
  }, [])

  if (isSafari === true || isIOS === true || isMacOs === true) {
    $('body').addClass('iosdevicesonly');
  }

  function searchOverlay(e) {
    $("body")
      .addClass("modal-open")
      .append("<div class='search-modal modal-backdrop fade'></div>")
    $(".search-overlay").addClass("active-search")
  }
  const data = useStaticQuery(graphql`
  query{
    glstrapi {
      contact(publicationState: LIVE) {
        Call_Contact_Number
     
    }
      menuHeaders(publicationState: LIVE, sort:"Sort:asc") {
        Parent_Label
        Sort
        Sub_Menus {
          Add_Sub_Menus {
            Label
            URL {
              Alias
            }
          }
          Parent_Label
          Parent_URL {
            Alias
          }
        }
        Publish
        Parent_URL {
          Alias
        }
      }
    }

  }
`);

const [parentIndex, setparentIndex] = useState(null)
const [subIndex, setsubIndex] = useState(null) 
const handlerOpenMenu = (index) => {
  if(parentIndex === index){
    setparentIndex(null)
    setsubIndex(null)  
  }
  else {
    setparentIndex(index)
    setsubIndex(null)
  }

};
const handlerOpenSubMenu = (e, index, j) => {
  if(subIndex === j){
    setsubIndex(null)
  }
  else {
    setsubIndex(j)
  }
  
};

    const [open, setOpen] = useState(false); 
    const openMenu = () => {
      setOpen(!open);
      setparentIndex(null)
      setsubIndex(null)
    };
  
    const closeMenu = () => {
      setOpen(false);

    };

    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
      window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })
    }, [])
    return (
        <React.Fragment>
          {/* <SearchOverlay /> */}
          <header className={`${open ? "open-navigation header" : props.relative && !scroll ? 'header relative-fix' : "header"} ${isSafari}-mac-saf`}>
            <Helmet>    
              <meta name="viewport" content="width=device-width; initial-scale=1.0, maximum-scale=1.0, user-scalabale=no" />
              <link rel="preconnect" href="https://www.google-analytics.com"></link>
              <link rel="preconnect" href="https://collegencounty-strapi.q.starberry.com"></link>
              <link rel="preconnect" href="https://ggfx-collegencounty.s3.eu-west-2.amazonaws.com"></link>
              <link rel="preconnect" href="https://www.googletagmanager.com"></link>
        
            </Helmet>
                <div className="header-container">
                    <div className="logo ">
                    <Link to="/">    
                    <picture>
                        <source media="(min-width:992px)" srcSet={LogoImgD} />
                        <source media="(min-width:768px)" srcSet={LogoImgT} />
                        <img src={LogoImgM} alt="Logo" height={40} width ={220}/>
                    </picture>
                    </Link>
                    </div>
                    <div className="menu-all">
                    <div className="main-primary-menu d-none d-xl-block">
                        <ul className="menu-primary">
                        {data.glstrapi.menuHeaders.map((node, i) => (
                            <li className={node.Sub_Menus.length !== 0 ? "megamenu-tab megamenu-large" : ""}>{node.Parent_URL?.Alias ? <GetURL i={i} class={active === i ? "active" : null} label={node.Parent_Label} alias={node.Parent_URL?.Alias} /> : <a href="javascript:;" >{node.Parent_Label}</a>}
                            {node.Sub_Menus.length !== 0 &&
                              <div className={i === 0 ? "megamenu smallmenu first" : node.Sub_Menus[0]?.Add_Sub_Menus?.length !== 0 ? "megamenu" : "megamenu smallmenu"}>
                                <div className="megamenu-inner">
                                <div className={node.Sub_Menus.length === 3 ? "mega-menu-row gridsize" : "mega-menu-row"}>
                                {node.Sub_Menus.map((Sub_Menus, i) => (
                                        <div className="mg-bottom-menu-item menu-block-column">
                                          {Sub_Menus.Parent_Label != null && Sub_Menus.Parent_URL?.Alias != null ?
                                            <span className={node.Sub_Menus[0]?.Add_Sub_Menus?.length !== 0 ? "mega-menu-list-head" : "sub-menu-link"}>
                                            <GetURL label={Sub_Menus.Parent_Label} alias={Sub_Menus.Parent_URL?.Alias} />
                                            </span>
                                            : 
                                            <span className={node.Sub_Menus[0]?.Add_Sub_Menus?.length !== 0 ? "mega-menu-list-head" : "sub-menu-link"}>
                                            <a href="javascript:;" >{Sub_Menus.Parent_Label}</a>
                                            </span>
                                          }
                                          <ul>
                                            {Sub_Menus.Add_Sub_Menus.map((Add_Sub_Menus, i) => (
                                              <li>
                                                <GetURL label={Add_Sub_Menus?.Label} alias={Add_Sub_Menus?.URL?.Alias} />
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      ))}
                                </div>
                                </div>
                              </div>
                              }
                              </li>
                            ))}
                        </ul>
                    </div>
                    <div className="menu-section">
                        <ul className="menu2-block d-none d-xl-block">
                            <li><Link to={VALUATION_PAGE_URL.alias}>Valuation</Link></li>
                            <li className="sb-myacc"><a href={myaccountURL}>My Account</a></li>
                        </ul>
                        
                        <div className="form-page-menu">
                          <div className="back-button-header">
                            {props.step > 0 && !props.gobackPage ? <a onClick={() => props.previous()} className="button-back btn-text-link" variant="link"><i className="icon-back"></i> Go Back</a> : 
                            <Link to={props.page === 'formnew' || props.gobackPage ? '/' : props.page === 'form' || props.gobackPage ? CONTACT_PAGE_URL.alias : '/'} className="button-back btn-text-link"  variant="link"><i className="icon-back"></i> Go Back</Link>}
                          </div>
                            <div className="user-contact-number">
                   
                              <span className="help-text">Need Help?</span>
                              <Link href={"tel:"+data.glstrapi?.contact?.Call_Contact_Number} className="btn-text-link">
                                <span>Call {uknumberformat(data.glstrapi?.contact?.Call_Contact_Number)}</span>
                              </Link>
                            </div>
                          
                        </div>
                        <Link href={"tel:"+data.glstrapi?.contact?.Call_Contact_Number} className="d-md-none"><i className="icon-phone-menu"></i></Link>
                        <Link to={BAC_PAGE_URL.alias} className="btn btn-outline d-none d-md-flex">Book a Consultation</Link>
                        <a href={myaccountURL} className="d-xl-none"><i className="icon-user"></i></a>
                        <button className="icon-search-button d-none d-md-block d-xl-none" type="button" onClick={searchOverlay}><i className="icon-search"></i></button>
                        <button type="button" onClick={openMenu} className="button-menu  d-xl-none">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <div className="menu-bar ">
                          <div className="mobile-header-menu">
                          <Link className="menu-home-link" to="/">    
                    <picture>
                        <source media="(min-width:992px)" srcSet={LogoImgD} />
                        <source media="(min-width:768px)" srcSet={LogoImgT} />
                        <img src={LogoImgM} alt="Logo" width={220} height={40} />
                    </picture>
                    </Link>
                    <Link href={"tel:"+data.glstrapi?.contact?.Call_Contact_Number} className="d-md-none"><i className="icon-phone-menu"></i></Link>
                          <button type="button" onClick={openMenu} className="button-menu  d-xl-none">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                            </div>
                   
                            <div className="menu-nav">
                                <ul className="main-menu-list">
                                {data.glstrapi.menuHeaders.map((node, i) => (
                                <li
                                  className={
                                    i === parentIndex
                                      ? "menu-item menu-item-has-children active-menu"
                                    :  "menu-item menu-item-has-children"
                                  }
                                  
                                >
                                  <div className="first-level">
                                  {node.Parent_URL?.Alias ? <GetURL i={i} label={node.Parent_Label} alias={node.Parent_URL?.Alias} /> :   <a href="javascript:;" >{node.Parent_Label}</a>}
                                  {node.Sub_Menus.length !== 0 && i === parentIndex ?
                                  <i onClick={() => handlerOpenMenu(i)} className="icon-minus-menu"></i>
                                  : node.Sub_Menus.length !== 0 && i !== parentIndex ?
                                    <i onClick={() => handlerOpenMenu(i)} className="icon-plus-menu"></i>
                                    : null
                                    }
                                  </div>
                                  {i === parentIndex && (
                                    <ul className="submenu-list">
                                      {node.Sub_Menus.map((Sub_Menus, j) => (
                                        <li
                                          className={
                                            j === subIndex
                                              ? "menu-item menu-item-has-children open active-menu"
                                              : "menu-item menu-item-has-children"
                                          }
                                          
                                        >
                                          <div className="second-level">
                                          {Sub_Menus.Parent_Label != null && Sub_Menus.Parent_URL?.Alias != null ?
                                            <GetURL label={Sub_Menus.Parent_Label} alias={Sub_Menus.Parent_URL?.Alias} />
                                          : <a href="javascript:;" >{Sub_Menus.Parent_Label}</a>
                                          }
                                  {Sub_Menus?.Add_Sub_Menus?.length !== 0 && j === subIndex ?
                                  <i onClick={(e) =>
                                    handlerOpenSubMenu(e, i, j)
                                  } 
                                  className="icon-minus-menu"></i>
                                  : Sub_Menus?.Add_Sub_Menus?.length !== 0 && j !== subIndex ?
                                    <i onClick={(e) =>
                                      handlerOpenSubMenu(e, i, j)
                                    }
                                     className="icon-plus-menu"></i>
                                    : null
                                    }
                                          
                                          </div>
                                          {j === subIndex && (
                                            <ul className="submenu-list-level2">
                                            {Sub_Menus.Add_Sub_Menus.map((Add_Sub_Menus, i) => (
                                                <li
                                                  onClick={(e) => e.stopPropagation()}
                                                  className="menu-item"
                                                >
                                                  <GetURL label={Add_Sub_Menus?.Label} alias={Add_Sub_Menus?.URL?.Alias} />
                                                </li>
                                              ))}
                                            </ul>
                                           )}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                                ))}
                                <li className="d-block d-xl-none"><Link to={VALUATION_PAGE_URL.alias}>Valuation</Link></li>
                                <li className="d-block d-xl-none"><Link to={BAC_PAGE_URL.alias} className="btn btn-outline mt-4">Book a Consultation</Link></li>
                                </ul>
                            </div>
                        </div>    
                    </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

export default Header;