import React, {useState} from 'react';
import { Link } from "@StarberryUtils"
import { useStaticQuery, graphql } from "gatsby";
import {
  SALE_PAGE_URL,
  RENT_PAGE_URL
} from "@Components/common/site/constants";
/**
 * Assets
 */

const GetURL = (props) => {

    const data = useStaticQuery(graphql`
    query{

      glstrapi {
        menus(publicationState: LIVE) {
          Alias
          URL
          External_URL
          Label
          Main_Parent {
            Alias
            URL
          }
          Sub_Parent {
            Alias
            URL
          }
          Link_Type
        }
      }

    }
  `);

  const PageURL = data.glstrapi?.menus.filter(item => item?.Alias === props?.alias).pop();

  

  var URL = PageURL?.URL

  if((PageURL?.Main_Parent != null) &&(PageURL?.Sub_Parent == null)) {
    URL = PageURL?.Main_Parent?.URL + '/' +URL
  }

  else if((PageURL?.Main_Parent != null) &&(PageURL?.Sub_Parent != null)) {
    URL = PageURL?.Main_Parent?.URL + '/' + PageURL?.Sub_Parent?.URL + '/' +URL
  }

    return (
    <>
    
         {PageURL?.Link_Type === 'Internal' && <>
         {URL === "property/for-sale" ?
         <Link to={SALE_PAGE_URL.alias} className={props.class}>
         {props.label} 
            
          </Link> :
                      URL === "property/to-rent" ? <Link to={RENT_PAGE_URL.alias} className={props.class}>
                      {props.label} 
                      
                    </Link> : 
                    props.i === 0 ? <Link className={props.class}>            
                    {props.label} 
      
                    {/* {props.isplus &&
                <i className="icon-vector" onClick={props.toggleNav} onKeyDown={props.toggleNav} aria-label="buy"></i>
                  } */}
                    
                </Link> :
          <Link to={`/${URL}`} className={props.class}>            
              {props.label} 

              {/* {props.isplus &&
          <i className="icon-vector" onClick={props.toggleNav} onKeyDown={props.toggleNav} aria-label="buy"></i>
            } */}
              
          </Link>
}
          </>
        }
        {PageURL?.Link_Type === 'External_New_Window' &&<>
        <a href={PageURL?.External_URL} target="_blank" className={props.class}>
        {props.label}
        </a>
        </>
        }



    </>
    )
}

export default GetURL

// Specifies the default values for props:
GetURL.defaultProps = {
    label: '',
    class: '',
    arrow: 'no',
    dropdown: 'no',
    style: '',
    URL: '',
    icon: ''
};
