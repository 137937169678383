import { URLS } from "@Components/common/site/constants";

let _URLS = URLS;

// --------------------------------------------------------------------------
// General functions - Start
export const addLinksToArray= (links, linksArray) => {
  if (links && linksArray) {
    links.map((link) => {
      linksArray.push(link);
    });
  }
}

export const getUrlName = (url) => {
  if (url) {
    return url.name2 ?? url.name;
  }
}

export const getUrlHref = () => {
  let urlHref = '';
  if (typeof window !== 'undefined') {
    urlHref = window.location.href;
  }
  return urlHref;
}

export const getUrlPathName = () => {
  let urlPathName = '';
  if (typeof window !== 'undefined') {
    urlPathName = window.location.pathname;
    urlPathName = urlPathName.toLowerCase();
  }
  return urlPathName;
}

export const getUrlLastPart = () => {
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  var url = urlPathName.split('/');
  // console.log('url : ' + url);
  let [last] = [...url].reverse();
  // console.log('last : ' + last);
  return last;
}

export const toTitleCase = (text) => {
  let _text = text || '';
  // console.log('_text : ' + _text);
  if ( _text ) {
    _text = _text.trim().split(' ');
    // console.log('_text.length : ' + _text.length);
    if ( _text.length > 0 ) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text;
}
// General functions - End  
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// is...Pages() Or is...Page() - functions - Start


export const isServicePage = () => {
  let isServicePage = false;
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  // console.log( '_URLS.PROPERTY_SERVICES_BUY.alias : ' + _URLS.PROPERTY_SERVICES_BUY.alias );
  if ( urlPathName && urlPathName.indexOf( _URLS.PROPERTY_SERVICES.alias ) > -1 ) {
    isServicePage = true;
  }
  // console.log('isBuyPages : ' + isBuyPages);
  return isServicePage;
}

export const isContactPage = () => {
  let isContactPage = false;
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  // console.log( '_URLS.PROPERTY_SERVICES_BUY.alias : ' + _URLS.PROPERTY_SERVICES_BUY.alias );
  if ( urlPathName && urlPathName.indexOf( _URLS.CONTACT.alias ) > -1 ) {
    isContactPage = true;
  }
  // console.log('isBuyPages : ' + isBuyPages);
  return isContactPage;
}


export const isAboutPage = () => {
  let isAboutPage = false;
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  // console.log( '_URLS.PROPERTY_SERVICES_BUY.alias : ' + _URLS.PROPERTY_SERVICES_BUY.alias );
  if ( urlPathName && urlPathName.indexOf( _URLS.ABOUT.alias ) > -1 ) {
    isAboutPage = true;
  }
  // console.log('isBuyPages : ' + isBuyPages);
  return isAboutPage;
}

export const isPropertyPage = () => {
  let isPropertyDetailsPage = false;
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  // console.log( '_URLS.PROPERTY_DETAILS.alias : ' + _URLS.PROPERTY_DETAILS.alias );
  if ( urlPathName && urlPathName.indexOf( _URLS.FORSALE.alias ) > -1 || urlPathName && urlPathName.indexOf( _URLS.TORENT.alias ) > -1 ) {
    isPropertyDetailsPage = true;
  }
  // console.log('isPropertyDetailsPage : ' + isPropertyDetailsPage);
  return isPropertyDetailsPage;
}

export const isPropertiesResultsPage = () => {
  let isPropertiesResultsPage = false;
  let urlPathName = getUrlPathName();
  // console.log('urlPathName : ' + urlPathName);
  // console.log( '_URLS.PROPERTIES_RESULTS_SALES.alias : ' + _URLS.PROPERTIES_RESULTS_SALES.alias );
  // console.log( '_URLS.PROPERTIES_RESULTS_RENTALS.alias : ' + _URLS.PROPERTIES_RESULTS_RENTALS.alias );
  if ( urlPathName && 
      ( urlPathName.indexOf( _URLS.TORENTS.alias ) > -1 || 
        urlPathName.indexOf( _URLS.FORSALES.alias ) > -1
      )
    ) {
    isPropertiesResultsPage = true;
  }
  // console.log('isPropertiesResultsPage : ' + isPropertiesResultsPage);
  return isPropertiesResultsPage;
}