import React, { useState, useEffect,useLayoutEffect } from "react";
import { Link } from "@StarberryUtils";
import {
    Container,
    Row,
    Col
  } from "react-bootstrap";
  import "./Footer.scss";
  import { useStaticQuery, graphql } from "gatsby";
  //import CookieConsent from "react-cookie-consent"
  import CookieConsent from "../CookieConsent/CookieConsent";
  import GetURL from '@Components/common/site/get-url';
  import Starberry from "../../images/starberry.svg";
  import { getSrc } from "gatsby-plugin-image"
  import {
 BAC_PAGE_URL
  } from "@Components/common/site/constants";
  import { isMacOs, isSafari } from 'react-device-detect';
  import loadable from '@loadable/component';
  const PopularSearch = loadable(() => import('./popular-search-static'));
  const PopularSearchDetails = loadable(() => import('./popular-search-details'));
  const PopularSearchDynamic = loadable(() => import('./popular-search-dynamic'));
  // import PopularSearch from "./popular-search-static";
  // import PopularSearchDetails from "./popular-search-details";
  // import PopularSearchDynamic from "./popular-search-dynamic";
const Footer = (props) => {
  // const [width, setWidth] = useState("auto");
  // const [height, setHeight] = useState("auto");

  // useLayoutEffect(() => {
  //   const handleResize = () => {
  //     const element = document.getElementById('footer');
  //     setWidth(element.offsetWidth);
  //     setHeight(element.offsetHeight);
  //   };

  //   window.addEventListener('resize', handleResize);
  //   handleResize();

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
      window.addEventListener("mousemove", () => {
          if (renderComponent === false) {
              setRenderComponent(true)
          }
      })
      window.addEventListener("touchmove", () => {
          if (renderComponent === false) {
              setRenderComponent(true)
          }
      })
      window.addEventListener("keypress", () => {
        if (renderComponent === false) {
            setRenderComponent(true)
        }
    })
    window.addEventListener("scroll", () => {
      if (renderComponent === false) {
          setRenderComponent(true)
      }
  }) 
     
  }, [])
  const year = new Date().getFullYear();
  const [search, setSearch] = useState(false);
  const [state, setState] = useState([
    { 
      name: "Selling",
      submenu: [
        {
          name: "Market Your Property",
          isActive: false,
        },
        {
          name: "Property Valuation",
          isActive: false,
        },
        {
          name: "Ethical Sales",
          isActive: false,
        },
        {
          name: "Guide to Selling",
          isActive: false,
        },
      ],
      isActive: false,
    },
    {
      name: "Buying",
      submenu: [
        {
          name: "Property for Sale",
          isActive: false,
        },
        {
          name: "Guide to Buying",
          isActive: false,
        },
        {
          name: "Investments",
          isActive: false,
        },
        {
          name: "Mortgages",
          isActive: false,
        },
      ],
      isActive: false,
    },
    {
      name: "Landlords",
      submenu: [
        {
          name: "Property Valuation",
          isActive: false,
        },
        {
          name: "Ethical Lettings",
          isActive: false,
        },
        {
          name: "Guide to Letting",
          isActive: false,
        },
      ],
      isActive: false,
    },
    {
      name: "Tenants",
      submenu: [
        {
          name: "Ethical Renting",
          isActive: false,
        },
        {
          name: "Guide to Renting",
          isActive: false,
        },
        {
          name: "Fees & Terms",
          isActive: false,
        },
        {
          name: "Tenant Support",
          isActive: false,
        },
      ],
      isActive: false,
    },
    {
      name: "Property Management",
      submenu: [
        {
          name: "Sign Up",
          isActive: false,
        },
        {
          name: "What's Included",
          isActive: false,
        },
        {
          name: "Help & Guides",
          isActive: false,
        },
      ],
      isActive: false,
    },
    {
      name: "About",
      submenu: [
        {
          name: "About College and County",
          isActive: false,
        },
        {
          name: "Meet the team",
          isActive: false,
        },
        {
          name: "News & Videos",
          isActive: false,
        },
        {
          name: "Careers",
          isActive: false,
        },
      ],
      isActive: false,
    },
  ]);

  const openSearch=()=>{
    setSearch(!search)
      setTimeout(() => {
          window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
      }, 200);
  }
  
  const [footerindex, setfooterindex] = useState(null) 
  const clickHandler = (index) => {
    if(footerindex === index) {
      setfooterindex(null)
    }
    else {
      setfooterindex(index)
    }
    
  }

  const data = useStaticQuery(graphql`
  query{
      glstrapi {
          menuFooter(publicationState: LIVE) {
            Footer_Menu_Block {
              Label
              Url {
                Alias
              }
              Sub_Footer_Menu {
                Label
                Url {
                  Alias
                }
              }
            }
          }
          contact(publicationState: LIVE) {
            Facebook_Link
            Instagram_Link
            Twitter_Link
            Youtube_Link
            Clients {
              Client_Url
              Client_Logo{
                  alternativeText
                  url
                  url_sharp {
                      childImageSharp {
                        gatsbyImageData(formats: AUTO
                          quality: 80
                          layout: FIXED
                          transformOptions: {cropFocus: CENTER, fit: COVER}
                        )
                      }
                    }
              }
            }
          }
        }
      }
            `);

    return (
      
        <React.Fragment>
          <footer className={`${(isSafari || isMacOs) ? "mac-saf" : ""} footer`}>
          <CookieConsent/>
            <Container>
            {/* <ScrollAnimation animateOnce={true} animateIn='fadeIn'> */}
              <Row className="footer-block1 footer-quick-links">
              {data.glstrapi?.menuFooter?.Footer_Menu_Block?.map((item, i) => (
                <Col md={4} lg={4} xl={2} className="footer-quick-link-list">
                <div className="footer-menu">
                  <div className={`footer-quick-link-block ${footerindex === i ? `open-footer-block` : ``}`}>
                    <div onClick={()=>clickHandler(i)} className="footer-quick-link-head">
                      <span>{item.Label}</span>
                      <i className="icon-minus-footer"></i>
                      <i className="icon-plus-footer"></i>
                    </div>
                    <div className="footer-all-links">
                      <ul>
                          {
                              item.Sub_Footer_Menu.map((newitem,i)=>
                                  <li><GetURL label={newitem.Label} alias={newitem.Url?.Alias} /></li>
                              )
                          }
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              ))}
              </Row>
            {/* </ScrollAnimation>  
            <ScrollAnimation animateOnce={true} animateIn='fadeIn'> */}
              <Row className="footer-block2 social-icons-terms-conditions">
                <div className="terms-and-conditions">
                  <ul>
                    <li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link to="/cookies">Cookies</Link></li>
                    <li><a href="javascript:void(0);" id="open_preferences_center">Update cookies preferences</a></li>
                    {/* <li><Link href="#">Accreditations</Link></li> */}
                  </ul>
                  <p>&copy; College and County {year}. All Rights Reserved. <span className="starberry-link">Site by <Link href="http://starberry.tv/" target="_blank" className="btn-text-link"><img src={Starberry} alt="Starberry" className="stb-logo"/></Link></span></p>
                </div>
                {renderComponent && 
                <div className="social-links">
                  <ul>
                    {data.glstrapi?.contact?.Twitter_Link &&
                    <li><Link target="_blank" href={data.glstrapi?.contact?.Twitter_Link}><i className="icon-twitter-footer"></i></Link></li>
                    }
                    {data.glstrapi?.contact?.Facebook_Link &&
                    <li><Link target="_blank" href={data.glstrapi?.contact?.Facebook_Link}><i className="icon-facebook-footer"></i></Link></li>
                    }
                    {data.glstrapi?.contact?.Instagram_Link &&
                    <li><Link target="_blank" href={data.glstrapi?.contact?.Instagram_Link}><i className="icon-instagram-footer"></i></Link></li>
                    }
                    {data.glstrapi?.contact?.Youtube_Link &&
                    <li><Link target="_blank" href={data.glstrapi?.contact?.Youtube_Link}><i className="icon-youtube-footer"></i></Link></li>
                    }
                  </ul>
                </div>
                }
                {renderComponent && 
                            <div className="logo-block ipad-view-below ">
                                {data.glstrapi?.contact?.Clients.map((node, i) => (
                                    <a href={node.Client_Url} target="_blank" >
                                        <img src={getSrc(node.Client_Logo?.url_sharp)} alt={node.Client_Logo?.alternativeText}  />
                                    </a>
                                ))}
                            </div>
                  }

              </Row>
              {renderComponent && 
                <div className="logo-block desktop-view-below">
                      {data.glstrapi?.contact?.Clients.map((node, i) => (
                      
                          <a href={node.Client_Url} target="_blank" >
                              <img src={getSrc(node.Client_Logo?.url_sharp)} alt={node.Client_Logo?.alternativeText}  />
                          </a>
                      ))}
                  </div>
              }
            {renderComponent &&
            <Row className="footer-block3 popular-searches">
                <div className={search ? "popular-search-block open-footer-block" : "popular-search-block"}>
                  <div className="popular-search-head" onClick={() => openSearch()}>
                    <span>Popular Searches</span>
                    <i className="icon-plus-footer"></i>
                    <i className="icon-minus-footer"></i>
                  </div>
                  {/* {props.searchtype} */}
                  {(props.searchtype === 'sales') || (props.searchtype === 'lettings')?
                  <PopularSearchDynamic searchtype={props.searchtype} searchBedroomfield={props.searchBedroomfield} searchPtype={props.searchPtype} Searcharea={props.Searcharea} />
                  :
                  (props.searchtype ==='details') ?
                  <PopularSearchDetails searchtype={props.property_type} property_building={props.property_building} property_address={props.property_address}/>
                  :
                  <Row>
                  <PopularSearch popularSearch={props.popularSearch} />
                  </Row>
                  }
                  
                  </div>
                  
                  
              </Row>
              }
              {/* <CookieConsent
                    disableStyles={true}
                    containerClasses="cookie-popup d-lg-flex d-block"
                    contentClasses=""
                    buttonClasses="btn btn-link gotit"
                    buttonText="Accept Cookies">

                    <p className="mb-0 text-center">We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to="/cookies">Cookie Policy</Link>.</p>
                </CookieConsent> */}
            </Container>
          </footer>
        </React.Fragment>
    )
}

export default Footer;