export const URLS = {
    NEWS: {
        name: 'News',
        alias: '/about/news'
      },
      AREAGUIDE: {
        name: 'Area Guide',
        alias: '/about/our-areas'
      },
      CONTACT: {
        name: 'Contact',
        alias: '/contact'
      },
      BAC: {
        name: 'Book a Consultation',
        alias: '/book-a-consultation'
      },

      CONTACT_FORM: {
        name: 'General Enquiry',
        alias: '/general-enquiry'
      },
      TEAM: {
        name: 'Teams',
        alias: '/about/meet-our-team'
      },
      ABOUT: {
        name: 'About',
        alias: '/about'
      },
      CAREERS: {
        name: 'Careers',
        alias: '/about/careers'
      },
      VALUATION: {
        name: 'Property Valuation',
        alias: '/valuation'
      },
      FORSALE: {
        name: 'Property for sale',
        alias: '/property-for-sale'
      },
      TORENT: {
        name: 'Property to rent',
        alias: '/property-to-rent'
      },
      SFORSALE: {
        name: 'For Sale',
        alias: '/property/for-sale/in-oxford-and-thame'
      },
      STORENT: {
        name: 'To Rent',
        alias: '/property/to-rent/in-oxford-and-thame'
      },
      FORSALES: {
        name: 'For Sale',
        alias: '/property/for-sale'
      },
      TORENTS: {
        name: 'To Rent',
        alias: '/property/to-rent'
      },
      PROPERTY_SERVICES: { // What We Do
        name: 'Property Services',
        alias: '/services',
      },
      Reviews: {
        name: 'Reviews',
        alias: "/about/reviews"
      }
      



}


export const FORSALE_PAGE_URL = URLS.FORSALE;
export const TORENT_PAGE_URL = URLS.TORENT;
export const RENT_PAGE_URL = URLS.STORENT
export const BAC_PAGE_URL = URLS.BAC;
export const SALE_PAGE_URL = URLS.SFORSALE
export const NEWS_PAGE_URL = URLS.NEWS;
export const CONTACT_PAGE_URL = URLS.CONTACT;
export const CONTACT_FORM_PAGE_URL = URLS.CONTACT_FORM;
export const TEAM_PAGE_URL = URLS.TEAM;
export const CAREERS_PAGE_URL = URLS.CAREERS;
export const VALUATION_PAGE_URL = URLS.VALUATION;
export const AREAGUIDE_PAGE_URL = URLS.AREAGUIDE;
export const PROPERTY_SERVICES_PAGE_URL = URLS.PROPERTY_SERVICES;
export const ABOUT_PAGE_URL = URLS.ABOUT;
export const Reviews_Page_Url = URLS.Reviews